import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { every, find, isUndefined, flatten } from 'lodash';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import COLORS from 'shared/constants/colors';
import STYLE_UTILS from 'shared/constants/style.utils';
import { Icon, IconType } from 'stories';
import FlexContainer from 'web/components/primitives/flex-container';

import style from './styles.scss';

const isSingleValue = val => typeof val === 'string' || typeof val === 'number';
export const IS_SELECT_ALL_VALUE = 'IS_SELECT_ALL_VALUE';

// eslint-disable-next-line
const MultiValue = props => {
  const {
    children,
    removeProps,
    innerRef,
    hasValue,
    genMultiValueIcon,
    data,
  } = props;
  return (
    <div
      data-qa-selected={hasValue}
      className={style.multiValueOption}
      onClick={removeProps.onClick}
      ref={innerRef}
    >
      <FlexContainer alignItems="center">
        {children}
        {genMultiValueIcon && genMultiValueIcon(data)}
        <Icon
          type={IconType.checkCircle}
          size={16}
          className={style.multiValueCheckIcon}
        />
      </FlexContainer>
    </div>
  );
};

const DropdownSelect = forwardRef(
  (
    {
      isMulti,
      isCreatable,
      isClearable,
      isGrouped,
      disableSelectAll,
      components,
      styles,
      value,
      options,
      onChange,
      theme,
      genMultiValueIcon,
      ...props
    },
    ref,
  ) => {
    const Dropdown = isCreatable ? Creatable : Select;
    let modifiedValue = value;
    const isArrayOfSingleValues =
      isMulti && (value || []).length && every(value, isSingleValue);
    if (isSingleValue(value) || isArrayOfSingleValues) {
      modifiedValue = isMulti
        ? value.map(val => find(options, { value: val }))
        : find(options, { value }) || '';
    }
    let onChangeHandler = onChange;
    let optionsToRender = options;
    let totalOptions = options;
    if (options.length && options[0].options) {
      totalOptions = flatten(options.map(opt => opt.options));
    }
    if (
      !disableSelectAll &&
      isMulti &&
      modifiedValue &&
      totalOptions.length > 3
    ) {
      const allAreSelected = totalOptions.length === modifiedValue.length;

      optionsToRender = [
        {
          label: allAreSelected ? 'Remove All' : 'Select All',
          value: IS_SELECT_ALL_VALUE,
        },
        ...options,
      ];
      onChangeHandler = (selected, action) => {
        if (
          (selected || []).length &&
          selected[selected.length - 1].value === IS_SELECT_ALL_VALUE
        ) {
          return onChange(allAreSelected ? [] : totalOptions, action);
        }
        return onChange(selected, action);
      };
    }
    return (
      <Dropdown
        closeMenuOnSelect={!isMulti}
        {...props}
        ref={ref}
        onChange={onChangeHandler}
        isMulti={isMulti}
        isGrouped={isGrouped}
        value={modifiedValue}
        options={optionsToRender}
        isClearable={!isUndefined(isClearable) ? isClearable : isMulti}
        components={{
          MultiValue: localProps => (
            <MultiValue {...localProps} genMultiValueIcon={genMultiValueIcon} />
          ),
          Option: ({ children, innerProps, innerRef, getStyles, ...rest }) => (
            <div
              data-qa-selected={rest.isSelected}
              data-qa-option
              data-qa={rest.data.label.toString().trim()}
              ref={innerRef}
              style={getStyles('option', rest)}
              {...innerProps}
            >
              {children}
            </div>
          ),
          ...components,
        }}
        styles={{
          menu: provided => ({
            ...provided,
            width: '102%',
            marginLeft: '-1%',
            zIndex: 10,
            border: STYLE_UTILS.base_border_style,
          }),
          option: (provided, opt) => ({
            ...provided,
            fontWeight: opt.value === IS_SELECT_ALL_VALUE ? 'bold' : 'normal',
            color: COLORS.helper_text,
            cursor: 'pointer',
            fontSize: '0.9rem',
          }),
          groupHeading: provided => ({
            ...provided,
            color: COLORS.helper_text,
            fontSize: '0.8rem',
          }),
          control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            border: !state.menuIsOpen
              ? STYLE_UTILS.base_border_style
              : STYLE_UTILS.active_border_style,
            '&:hover': {
              border: !state.menuIsOpen
                ? STYLE_UTILS.base_border_style
                : STYLE_UTILS.active_border_style,
            },
            '&:active, &:focus': {
              border: STYLE_UTILS.active_border_style,
            },
          }),
          input: provided => ({
            ...provided,
            input: {
              height: 'initial !important',
              marginBottom: 'initial !important',
            },
          }),
          valueContainer: provided => ({
            ...provided,
            maxHeight: '150px',
            overflowY: 'auto',
          }),
          ...styles,
        }}
        theme={baseTheme => ({
          ...baseTheme,
          ...theme,
          colors: {
            ...baseTheme.colors,
            primary: COLORS.white_highlight,
            // hover color
            primary25: COLORS.white_highlight,
            primary50: COLORS.white_highlight,
            primary75: COLORS.emerald,
            danger: COLORS.cardinal,
            dangerLight: COLORS.cardinal,
            neutral0: COLORS.white,
            neutral5: COLORS.gray_000,
            neutral10: COLORS.gray_200,
            neutral20: COLORS.gray_300,
            neutral30: COLORS.gray_300,
            neutral40: COLORS.gray_400,
            neutral50: COLORS.gray_400,
            neutral60: COLORS.gray_500,
            neutral70: COLORS.gray_600,
            neutral80: COLORS.gray_800,
            neutral90: COLORS.gray_900,
            ...(theme.colors || {}),
          },
        })}
      />
    );
  },
);

DropdownSelect.propTypes = {
  maxMenuHeight: PropTypes.number,
  isMulti: PropTypes.bool,
  genMultiValueIcon: PropTypes.func,
  isCreatable: PropTypes.bool,
  isGrouped: PropTypes.bool,
  isClearable: PropTypes.bool,
  disableSelectAll: PropTypes.bool,
  styles: PropTypes.shape(),
  components: PropTypes.shape(),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
    ),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) //
            .isRequired,
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        }),
      ),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  theme: PropTypes.shape(),
};

DropdownSelect.defaultProps = {
  maxMenuHeight: 250,
  isMulti: false,
  isCreatable: false,
  isGrouped: false,
  disableSelectAll: false,
  isClearable: undefined,
  styles: {},
  components: {},
  value: undefined,
  theme: {},
};

export default DropdownSelect;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from 'stories';
import FlexContainer from 'web/components/primitives/flex-container';

import { includes } from 'lodash';
import styles from './styles.scss';

export default function ButtonGroup({
  className,
  mini,
  options,
  value,
  onChange,
  disabled,
}) {
  return (
    <FlexContainer
      alignItems="center"
      justifyContent="center"
      className={classNames(className, styles.container, {
        [styles['container--mini']]: mini,
        [styles['container--disabled']]: disabled,
      })}
    >
      {options.map(option => (
        <FlexContainer
          flex={1}
          key={option.value}
          alignItems="center"
          justifyContent="center"
          data-qa-option={option.value}
          className={classNames(styles.child, {
            [styles['child--selected']]:
              value === option.value ||
              (Array.isArray(value) && includes(value, option.value)),
            [styles['child--iconOnly']]: !option.label,
            [styles['child--disabled']]: disabled,
          })}
          onClick={e => {
            if (!disabled) {
              e.stopPropagation();
              onChange(option.value);
            }
          }}
        >
          {!!option.icon && (
            <Icon
              className={option.label ? styles.icon : undefined}
              type={option.icon}
              size={mini ? 12 : 20}
            />
          )}
          {option.label}
        </FlexContainer>
      ))}
    </FlexContainer>
  );
}

ButtonGroup.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      icon: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  mini: PropTypes.bool,
  disabled: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  className: undefined,
  value: undefined,
  mini: false,
  disabled: false,
};

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';

import 'normalize.css';
import 'toastr/build/toastr.min.css';
import 'web/styles/global.scss';

import ForgotPassword from 'web/components/public/forgot-password';
import ResetPassword from 'web/components/public/reset-password';
import Unsubscribe from 'web/components/public/unsubscribe';
import UnsubscribeYes from 'web/components/public/unsubscribe-yes';
import UnsubscribeNo from 'web/components/public/unsubscribe-no';
import Register from 'web/components/public/register';
import Privacy from 'web/components/public/privacy';
import Wrapper from 'web/components/public/wrapper';
import Welcome from 'web/components/public/welcome';
import Login from 'web/components/public/login';
import TrialExpired from 'web/components/public/trial-expired';
import Terms from 'web/components/public/terms';
import MasterServiceAgreementTerms from 'web/components/public/masterServiceAgreementTerms';

ReactDOM.render(
  <Router>
    <Wrapper>
      <Route path="/login" component={Login} />
      <Route path="/trial-expired" component={TrialExpired} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/:tenant/terms" component={Terms} />
      <Route
        path="/:tenant/accept-msa"
        component={MasterServiceAgreementTerms}
      />
      <Route path="/:tenant/privacy" component={Privacy} />
      <Route path="/:tenant/welcome/:id" component={Welcome} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/:tenant/invitation/:id" component={Register} />
      <Route path="/:tenant/unsubscribe/:email" component={Unsubscribe} />
      <Route
        path="/:tenant/unsubscribe-yes/:email"
        component={UnsubscribeYes}
      />
      <Route path="/:tenant/unsubscribe-no/:email" component={UnsubscribeNo} />
      <Route path="/:tenant/reset-password/:id" component={ResetPassword} />
      <Redirect to="/login" />
    </Wrapper>
  </Router>,
  document.getElementById('root'),
);
